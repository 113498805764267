<template>
    <div class="userinfo">
        <el-avatar size="large" shape="square" :src="userInfo.avatar" />
        <div class="">
            <span>学员ID: {{ userInfo.id }}</span>
            <span>{{ userInfo.nickname }}</span>
        </div>
    </div>
</template>
<script>
export default {
    props: ['userInfo'],
}
</script>
<style lang="less" scoped>
.userinfo {
    min-width: 120px;
    display: flex;
    .el-avatar {
        flex-shrink: 0;
    }
    > div {
        margin-left: 12px;
        flex-grow: 1;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
    }
}
</style>
