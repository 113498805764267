<template>
    <div class="page_container">
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item">
                    <span class="condition_label">项目</span>
                    <span class="condition_content">
                        <ProjectSelect @change="onProjectNoChange" />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">购买商品</span>
                    <span class="condition_content">
                        <ProductSelect ref="productSelect" :clearable="true" @change="(val) => (productNo = val)" />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">购买状态</span>
                    <span class="condition_content">
                        <el-select v-model="status" placeholder="请选择">
                            <el-option v-for="i in paidOrderStatusList" :key="i.val" :label="i.name" :value="i.val" />
                        </el-select>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_content">
                        <el-input
                            placeholder="请输入内容"
                            @change="onUserIdChange"
                            v-model.trim="queryTypeValue"
                            @keyup.enter.native="onPaginationChange('page', 1)"
                        >
                            <el-select
                                @change="onQuerySelectChange"
                                v-model="queryType"
                                class="query_type"
                                slot="prepend"
                            >
                                <el-option label="学员昵称" value="nickname" />
                                <el-option label="姓名" value="name" />
                                <el-option label="机会ID" value="oppoId" />
                                <el-option label="学员ID" value="userId" />
                                <el-option label="订单ID" value="orderId" />
                            </el-select>
                        </el-input>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">支付时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            :unlink-panels="true"
                            v-model="paidTimes"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            value-format="yyyy-MM-dd"
                            clearable
                        />
                    </span>
                </div>
                <div class="condition_item" v-if="manageGroupList.length !== 0">
                    <span class="condition_label">运营</span>
                    <span class="condition_content">
                        <StaffCascader
                            ref="staffCascader"
                            :manageGroupList="manageGroupList"
                            :clearable="true"
                            @change="(val) => (staffId = val[0])"
                        />
                    </span>
                </div>
            </div>
            <div class="opt_container">
                <el-button plain @click="onReset">重置</el-button>
                <el-button @click="onPaginationChange('page', 1)" type="primary">查询</el-button>
            </div>
        </div>
        <el-table :data="tableData" stripe border>
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column align="center" prop="oppoId" label="机会ID" width="90" />
            <el-table-column align="center" width="100" label="渠道">
                <template slot-scope="scope">
                    <span v-if="scope.row.providerInfo">{{ scope.row.providerInfo.name }}</span>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column align="center" width="100" label="姓名">
                <template slot-scope="scope">
                    <span v-if="scope.row.userInfo">{{ scope.row.userInfo.name || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="微信信息">
                <template slot-scope="scope">
                    <UserView v-if="scope.row.userInfo" :key="scope.row.id" :userInfo="scope.row.userInfo" />
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column header-align="center" label="购买商品">
                <template slot-scope="scope">
                    <span v-if="scope.row.productInfo">{{ scope.row.productInfo.title }}</span>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column header-align="center" prop="paidTime" label="支付时间" />
            <el-table-column align="center" label="购买状态" width="100">
                <template slot-scope="scope">
                    <Tag :color="scope.row.stateColor">
                        {{ paidOrderStatusMap[scope.row.state] }}
                    </Tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="运营" width="100">
                <template slot-scope="scope">
                    <StaffName :staff="scope.row.staff" />
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="90">
                <template slot-scope="scope">
                    <el-link
                        v-if="P(1050201) && scope.row.state !== 'REFUND'"
                        type="danger"
                        @click="onOrderRefund(scope.row)"
                    >
                        关闭订单
                    </el-link>
                    <span v-else>-</span>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            background
            @size-change="(val) => onPaginationChange('size', val)"
            @current-change="(val) => onPaginationChange('page', val)"
            :current-page.sync="page"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        />

        <UserDetailsDrawer
            :isShow="isShowUserDetailsDrawer"
            :activeItem="activeItem"
            @onClose="onCloseUserDetailsDrawer"
        />
    </div>
</template>
<script>
import { filterQueryEmpty, isUserId, isEmptyArray, generateColorFromString } from '@/js/utils.js'
import { getProductList } from '@/js/api/shopApi.js'
import { xbProviderList } from '@/js/api/advertApi.js'
import { getPaidUser, userList, refundTradePayOrder } from '@/js/api/userApi.js'
import { getStaffGroupList, getStaffList } from '@/js/api/staffApi.js'
import { paidOrderStatusList, paidOrderStatusMap } from '@/js/config/order.js'
import UserDetailsDrawer from './components/paidList/UserDetailsDrawer.vue'
import StaffCascader from '@/views/components/StaffCascader'
import ProductSelect from '@/views/components/ProductSelect'
import ProjectSelect from '@/views/components/ProjectSelect'
import UserView from '@/views/components/UserView'
import { mapGetters } from 'vuex'

let _localStorageKey = 'paidList_queryType'
export default {
    components: { UserDetailsDrawer, ProjectSelect, StaffCascader, ProductSelect, UserView },
    computed: { ...mapGetters(['userInfo']) },
    data() {
        return {
            paidOrderStatusList,
            paidOrderStatusMap,
            projectNo: '',
            productNo: '',
            status: '',
            queryTypeValue: '',
            queryType: '',
            paidTimes: [],
            staffId: '',

            manageGroupList: [],

            tableData: [],
            page: 1,
            pageSize: 10,
            total: 0,

            activeItem: null,
            isShowUserDetailsDrawer: false,
        }
    },
    created() {
        this.queryType = localStorage.getItem(_localStorageKey) || 'nickname'

        this.getStaffGroupData()
    },
    methods: {
        onReset() {
            this.productNo = ''
            this.status = ''
            this.queryTypeValue = ''
            this.paidTimes = []
            this.staffId = ''
            if (this.$refs.staffCascader) {
                this.$refs.staffCascader.onClear()
            }
            if (this.$refs.productSelect) {
                this.$refs.productSelect.onClear()
            }

            this.onPaginationChange('page', 1)
        },

        onProjectNoChange(val) {
            this.projectNo = val
            this.onReset()
        },

        onUserIdChange() {
            if (this.queryType === 'userId' && this.queryTypeValue && !isUserId(this.queryTypeValue)) {
                this.queryTypeValue = ''
                this.$message.warning('请输入正确的 学员ID')
            }
        },
        onQuerySelectChange() {
            localStorage.setItem(_localStorageKey, this.queryType)
            this.onUserIdChange()
        },

        // 学员详情
        onShowUserDetailsDrawer(item) {
            this.activeItem = item
            this.isShowUserDetailsDrawer = true
        },
        onCloseUserDetailsDrawer() {
            this.activeItem = null
            this.isShowUserDetailsDrawer = false
            this.getPaidUserApi()
        },

        // 关闭订单
        onOrderRefund(item) {
            this.$confirm('此操作只将状态置为已退费，不会实际出款，不可逆, 是否继续?', '关闭订单', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    refundTradePayOrder({ orderId: item.orderId, userId: item.userId }).then((res) => {
                        if (res.data) {
                            this.$message.success('操作成功')
                            this.getPaidUserApi()
                        }
                    })
                })
                .catch(() => {})
        },

        onPaginationChange(type, value) {
            if (type === 'size') {
                this.pageSize = value
                this.page = 1
            } else {
                this.page = value
            }
            this.$nextTick(this.getPaidUserApi)
        },

        getParams() {
            let params = { projectNo: this.projectNo, productNo: this.productNo, status: this.status }
            params[this.queryType] = this.queryTypeValue
            params.paidTimes = !isEmptyArray(this.paidTimes) ? this.paidTimes : null
            if (this.userInfo.isAdmin) {
                // 管理员
                params.staffId = this.staffId
            } else if (this.manageGroupList.length !== 0) {
                // 主管
                if (!this.staffId) {
                    params.groupIds = this.manageGroupList.map((i) => i.id)
                } else {
                    params.staffId = this.staffId
                }
            } else {
                // 员工
                params.staffId = this.userInfo.id
            }

            params = filterQueryEmpty(params)
            return params
        },

        async getPaidUserApi() {
            let params = {
                page: this.page,
                size: this.pageSize,
                showPage: true,
                ...this.getParams(),
            }

            params = filterQueryEmpty(params)

            const res1 = await getPaidUser(params)
            if (res1.data.list.length !== 0) {
                const _userIds = []
                const _productNos = []
                const _providerNos = []
                const _staffIds = []
                res1.data.list.forEach((item) => {
                    if (!_userIds.includes(item.userId)) {
                        _userIds.push(item.userId)
                    }
                    if (!_productNos.includes(item.productNo)) {
                        _productNos.push(item.productNo)
                    }

                    if (!_providerNos.includes(item.providerNo)) {
                        _providerNos.push(item.providerNo)
                    }

                    if (!_staffIds.includes(item.staffId)) {
                        _staffIds.push(item.staffId)
                    }
                })
                const res2 = await Promise.all([
                    userList({ ids: _userIds }),
                    getProductList({ productNos: _productNos }),
                    xbProviderList({ providerNos: _providerNos }),
                    getStaffList({ staffIds: _staffIds }),
                ])

                const _userMap = {}
                res2[0].data.forEach((i) => {
                    _userMap[i.id] = i
                })

                const _productMap = {}
                res2[1].data.forEach((i) => {
                    _productMap[i.productNo] = i
                })

                const _providerMap = {}
                res2[2].data.forEach((i) => {
                    _providerMap[i.providerNo] = i
                })

                const _staffMap = {}
                res2[3].data.forEach((i) => {
                    _staffMap[i.id] = i
                })
                res1.data.list.forEach((item) => {
                    item.userInfo = _userMap[item.userId]
                    item.productInfo = _productMap[item.productNo]
                    item.providerInfo = _providerMap[item.providerNo]
                    item.staff = _staffMap[item.staffId]
                    item.stateColor = generateColorFromString(item.state)
                })
            }
            this.tableData = res1.data.list
            this.page = res1.data.pageNum
            this.total = res1.data.total
        },
        getStaffGroupData() {
            const params = {}
            if (!this.userInfo.isAdmin) params.manager = this.userInfo.id
            getStaffGroupList(params).then((res) => {
                this.manageGroupList = res.data
            })
        },
    },
}
</script>
<style lang="less" scoped>
.attend_course {
    display: flex;
    .el-radio-group {
        flex-shrink: 0;
    }
    .course_type {
        flex-shrink: 0;
        width: 100px !important;
    }
    .attend_duration {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        .el-input-number {
            width: 90px;
        }
    }
}
.user_info {
    display: flex;
    align-items: center;
    > div {
        margin-left: 12px;
    }
}
</style>
