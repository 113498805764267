<template>
    <el-table :data="tableData" stripe border>
        <el-table-column align="center" type="index" label="序号" width="72" />
        <el-table-column header-align="center" prop="payTimeEnd" label="支付时间" />
        <el-table-column header-align="center" prop="tradeNo" label="订单号" />
        <el-table-column header-align="center" label="支付渠道">
            <template slot-scope="scope">
                <span v-if="orderProviderMap && orderProviderMap[scope.row.tradeProvider]">
                    {{ orderProviderMap[scope.row.tradeProvider]['providerName'] }}
                </span>
                <span v-else>{{ scope.row.tradeProvider }}</span>
            </template>
        </el-table-column>
        <el-table-column label="支付金额" width="100">
            <template slot-scope="scope">
                <span>¥{{ scope.row.fee / 100 }}</span>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
import { getProviders } from '@/js/api/shopApi.js'
import { getOrderDetail } from '@/js/api/userApi.js'

export default {
    props: ['activeItem'],
    data() {
        return {
            tableData: [],
            orderProviderMap: null,
        }
    },
    created() {
        this.getOrderDetailApi()
    },
    methods: {
        getOrderDetailApi() {
            getOrderDetail({ orderId: this.activeItem.orderId }).then((res) => {
                this.tableData = res.data
                if (res.data.length !== 0) {
                    this.getProvidersApi()
                }
            })
        },
        getProvidersApi() {
            getProviders({}).then((res) => {
                const orderProviderMap = {}
                res.data.forEach((item) => {
                    orderProviderMap[item.providerNo] = item
                })
                this.orderProviderMap = orderProviderMap
            })
        },
    },
}
</script>
