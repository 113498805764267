<template>
    <el-dialog
        title="退款申请"
        :destroy-on-close="true"
        :append-to-body="true"
        :visible.sync="isShow"
        :before-close="onClose"
        width="420px"
        @opened="onOpened"
    >
        <el-form ref="formData" v-if="formData" :model="formData" label-width="80px" :rules="rules">
            <el-form-item label="报名时间" v-if="activeItem">
                <el-input disabled :value="activeItem.paidTime" />
            </el-form-item>
            <el-form-item label="申请时间" prop="applyTime">
                <el-date-picker
                    v-model="formData.applyTime"
                    type="datetime"
                    placeholder="选择开始时间"
                    value-format="yyyy-MM-dd HH:mm:ss"
                />
            </el-form-item>
            <el-form-item label="退款类型" prop="refundType">
                <el-radio-group v-model="formData.refundType">
                    <template v-for="item in refundTypeList">
                        <el-radio-button v-if="item.orderState.includes('PAID')" :key="item.val" :label="item.val">
                            {{ item.name }}
                        </el-radio-button>
                    </template>
                </el-radio-group>
                <template v-for="item in refundTypeList">
                    <div :key="item.val" v-if="formData.refundType === item.val" class="refundType_desc">
                        {{ item.desc }}
                    </div>
                </template>
            </el-form-item>
            <el-form-item label="退款原因" prop="refundReason">
                <el-input :maxlength="128" show-word-limit type="textarea" v-model="formData.refundReason" :rows="3" />
            </el-form-item>
            <el-form-item label="原因分类" prop="refundReasonType">
                <el-select class="query_type" v-model="formData.refundReasonType" clearable placeholder="请选择">
                    <el-option
                        v-for="item in refundReasonList"
                        :label="item.name"
                        :value="item.type"
                        :key="item.type"
                    />
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { refundTypeList } from '@/js/config/order.js'
import { tradePayOrderRefundAdd } from '@/js/api/userApi.js'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
    props: ['isShow', 'activeItem', 'refundReasonList'],
    computed: { ...mapGetters(['userInfo']) },
    data() {
        const applyTimeValidator = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('请选择退款时间'))
            }
            const timeState = dayjs(this.activeItem.paidTime).isAfter(dayjs(this.formData.applyTime))
            return timeState ? callback(new Error('申请时间异常')) : callback()
        }
        return {
            refundTypeList,
            formData: null,
            rules: {
                applyTime: [
                    { required: true, message: '请选择', trigger: 'blur' },
                    { validator: applyTimeValidator, trigger: 'blur' },
                ],
                refundType: [{ required: true, message: '请选择', trigger: 'blur' }],
                refundReason: [{ required: true, message: '请输入', trigger: 'blur' }],
                refundReasonType: [{ required: true, message: '请选择', trigger: 'blur' }],
            },
        }
    },
    methods: {
        onOpened() {
            const formData = {
                applyTime: '',
                refundReason: '',
                refundType: '',
                refundReasonType: '',
                orderId: this.activeItem.orderId,
                userId: this.activeItem.userId,
                staffId: this.userInfo.id,
            }
            this.formData = formData
        },
        onClose() {
            this.$emit('onClose')
        },
        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    tradePayOrderRefundAdd({ ...this.formData }).then(() => {
                        this.$message.success('申请成功')
                        this.$emit('onClose', true)
                    })
                }
            })
        },
    },
}
</script>
