<template>
    <el-dialog
        :title="refundInfo && refundInfo.refund ? '挽留失败' : '挽留记录'"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :append-to-body="true"
        :visible.sync="isShow"
        :before-close="onClose"
        width="420px"
        @opened="onOpened"
    >
        <el-form ref="formData" v-if="refundInfo" :model="formData" label-width="110px" :rules="rules">
            <template v-if="refundInfo.refund">
                <el-form-item label="退款原因" prop="refundReason">
                    <el-input
                        class="el-input-auto"
                        type="textarea"
                        :maxlength="128"
                        show-word-limit
                        v-model="formData.refundReason"
                        :rows="3"
                    />
                </el-form-item>
                <el-form-item label="原因分类" prop="refundReasonType">
                    <el-select v-model="formData.refundReasonType">
                        <el-option
                            v-for="item in refundReasonList"
                            :label="item.name"
                            :value="item.type"
                            :key="item.type"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="退款类型" prop="refundType">
                    <el-radio-group v-model="formData.refundType">
                        <template v-for="item in refundTypeList">
                            <el-radio-button v-if="item.orderState.includes('PAID')" :key="item.val" :label="item.val">
                                {{ item.name }}
                            </el-radio-button>
                        </template>
                    </el-radio-group>
                    <template v-for="item in refundTypeList">
                        <div :key="item.val" v-if="formData.refundType === item.val" class="refundType_desc">
                            {{ item.desc }}
                        </div>
                    </template>
                </el-form-item>
                <el-form-item v-if="refundInfo.refund" label="协商退款金额" prop="expectFee">
                    <el-input-number
                        placeholder="请输入金额"
                        :min="1"
                        :controls="false"
                        v-model="formData.expectFee"
                        :precision="2"
                    />
                </el-form-item>
            </template>
            <template v-else>
                <el-form-item label="挽留记录" prop="content">
                    <el-input type="textarea" :maxlength="128" show-word-limit v-model="formData.content" :rows="3" />
                </el-form-item>
                <el-form-item label="挽留时间" prop="addTime">
                    <el-date-picker
                        style="width: 100%"
                        v-model="formData.addTime"
                        type="datetime"
                        placeholder="选择开始时间"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    />
                </el-form-item>
            </template>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { refundTypeList } from '@/js/config/order.js'
import { refundAdviseAdd, tradePayOrderRefundMod, getRefundReason } from '@/js/api/userApi.js'
import { numToCent } from '@/js/numTools.js'
import { mapGetters } from 'vuex'

export default {
    props: ['isShow', 'refundInfo', 'refundState'],
    computed: { ...mapGetters(['userInfo']) },
    data() {
        return {
            refundTypeList,
            refundReasonList: [],
            formData: { expectFee: undefined, content: '', refundType: '', addTime: '' },
            rules: {
                refundReasonType: [{ required: true, message: '请选择', trigger: 'blur' }],
                refundReason: [{ required: true, message: '请输入', trigger: 'blur' }],
                refundType: [{ required: true, message: '请选择', trigger: 'blur' }],
                expectFee: [{ required: true, message: '请输入', trigger: 'blur' }],
                content: [{ required: true, message: '请输入', trigger: 'blur' }],
                addTime: [{ required: true, message: '请选择', trigger: 'blur' }],
            },
        }
    },
    methods: {
        onOpened() {
            this.formData = {
                expectFee: undefined,
                refundReasonType: this.refundInfo.refundReasonType || '',
                refundReason: this.refundInfo.refundReason || '',
                refundType: '',
                content: '',
                addTime: '',
            }
            this.getRefundReasonApi()
        },
        onClose() {
            this.$emit('onClose', false)
        },
        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    if (this.refundInfo.refund) {
                        tradePayOrderRefundMod({
                            id: this.refundInfo.id,
                            refundState: 'REVIEW',
                            staffId: this.userInfo.id,
                            refundType: this.formData.refundType,
                            refundReason: this.formData.refundReason,
                            refundReasonType: this.formData.refundReasonType,
                            expectFee: numToCent(this.formData.expectFee),
                        }).then(() => {
                            this.$emit('onClose', 'REVIEW')
                            this.$message.success('已转至主管审核')
                        })
                    } else {
                        refundAdviseAdd({
                            staffId: this.userInfo.id,
                            refundId: this.refundInfo.id,
                            content: this.formData.content,
                            addTime: this.formData.addTime,
                        }).then(() => {
                            this.$message.success('添加成功')
                            this.$emit('onClose', true)
                        })
                    }
                }
            })
        },
        getRefundReasonApi() {
            getRefundReason().then((res) => {
                this.refundReasonList = res.data
            })
        },
    },
}
</script>
<style lang="less" scoped>
.refundType_desc {
    font-size: 13px;
    color: #666;
    margin-top: 6px;
    line-height: 1em;
}
</style>
