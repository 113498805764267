<template>
    <div>
        <!-- 正常订单 -->
        <el-button
            v-if="refundState === 'PAID'"
            @click="isShowEditRefundDialog = true"
            :disabled="!editState"
            plain
            type="danger"
        >
            申请退款
        </el-button>
        <el-table
            :data="tableData"
            highlight-current-row
            border
            :expand-row-keys="expandRow"
            row-key="id"
            @expand-change="toggleRowExpansion"
        >
            <el-table-column type="expand">
                <template slot-scope="props">
                    <div class="refund_details">
                        <div class="label">退款原因:</div>
                        <pre>{{ props.row.refundReason }}</pre>
                    </div>
                    <div class="refund_details">
                        <div class="label">挽留记录:</div>
                        <div class="refund_list">
                            <div v-for="item in props.row.adviseList" :key="item.id">
                                <div class="time">
                                    <span>{{ item.adviseTime }}</span>
                                    <!-- <span>创建时间：{{ item.addTime }}</span> -->
                                </div>
                                <pre>{{ item.content }}</pre>
                            </div>
                        </div>
                    </div>
                    <!-- 申请退款中 -->
                    <div class="opts" v-if="props.row.refundState === 'DETAINING' && editState">
                        <el-button @click="onShowEditAdviseInfo(props.row)" type="primary">添加挽留记录</el-button>
                        <el-button @click="onShowEditRetained(props.row)" plain type="primary">挽留成功</el-button>
                        <el-button @click="onShowEditAdviseInfo(props.row, true)" plain type="warning">
                            挽留失败
                            <i class="el-icon-right el-icon--right" />
                        </el-button>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="applyTime" label="申请时间" width="160"></el-table-column>
            <el-table-column prop="refundReasonType" label="退款原因分类">
                <template slot-scope="scope">
                    <span v-if="refundReasonMap">{{ refundReasonMap[scope.row.refundReasonType] || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="协商退款金额">
                <template slot-scope="scope">
                    <span>{{ scope.row.expectFee ? `¥${scope.row.expectFee / 100}` : '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="申请状态">
                <template slot-scope="scope">
                    <el-tag :type="refundStatusMap[scope.row.refundState]['tag']">
                        {{ refundStatusMap[scope.row.refundState]['text'] }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="财务出款" width="90">
                <template slot-scope="scope">
                    <span v-if="scope.row.refundTime">{{ scope.row.refundTime }}</span>
                    <span v-else>-</span>
                </template>
            </el-table-column>
        </el-table>
        <EditRefundDialog
            :refundReasonList="refundReasonList"
            :activeItem="activeItem"
            :isShow="isShowEditRefundDialog"
            @onClose="onCloseEditRefundDialog"
        />

        <EditAdviseInfo
            :isShow="isShowEditAdviseInfo"
            :refundInfo="refundInfo"
            :refundState="refundState"
            @onClose="onCloseEditAdviseInfo"
        />

        <EditRetained :isShow.sync="isShowEditRetained" :refundInfo="refundInfo" @onClose="onCloseEditRetained" />
    </div>
</template>
<script>
import { tradePayOrderRefundList, refundAdviseList, getRefundReason } from '@/js/api/userApi.js'
import { refundStatusMap } from '@/js/config/order.js'
import { mapGetters } from 'vuex'
import EditAdviseInfo from './EditAdviseInfo.vue'
import EditRefundDialog from './EditRefundDialog.vue'
import EditRetained from './EditRetained.vue'

export default {
    props: ['activeItem', 'refundState', 'editState'],
    components: { EditAdviseInfo, EditRefundDialog, EditRetained },
    computed: {
        ...mapGetters(['userInfo']),
    },
    data() {
        return {
            refundReasonMap: null,
            refundReasonList: [],
            refundStatusMap,

            expandRow: [],
            tableData: [],

            refundInfo: null,
            isShowEditAdviseInfo: false,
            isShowEditRefundDialog: false,
            isShowEditRetained: false,
        }
    },
    created() {
        this.tradePayOrderRefundListApi()
        this.getRefundReasonApi()
    },
    methods: {
        onCloseEditRefundDialog(status) {
            this.isShowEditRefundDialog = false
            if (status === true) {
                this.$emit('refundStateChange', 'DETAINING')
                this.tradePayOrderRefundListApi()
            }
        },
        onShowEditAdviseInfo(item, state) {
            this.refundInfo = { ...item, refund: state }
            this.isShowEditAdviseInfo = true
        },
        onCloseEditAdviseInfo(status) {
            if (status === true) {
                this.tradePayOrderRefundListApi(this.refundInfo)
            } else if (status === 'REVIEW') {
                this.tradePayOrderRefundListApi(this.refundInfo)
                this.$emit('refundStateChange', status)
            }
            this.refundInfo = null
            this.isShowEditAdviseInfo = false
        },

        onShowEditRetained(item) {
            this.refundInfo = item
            this.isShowEditRetained = true
        },
        onCloseEditRetained(status) {
            this.isShowEditRetained = false
            if (status) {
                this.$emit('refundStateChange', 'PAID')
                this.tradePayOrderRefundListApi()
            }
        },
        tradePayOrderRefundListApi() {
            tradePayOrderRefundList({
                showPage: false,
                orderBy: '-id',
                orderIds: [this.activeItem.orderId],
            }).then((res) => {
                res.data.forEach((item) => {
                    item.adviseList = []
                })
                this.tableData = res.data
                // 默认展开第一个
                if (res.data.length !== 0) {
                    this.expandRow = []
                    this.toggleRowExpansion(res.data[0])
                }
            })
        },
        toggleRowExpansion(row) {
            if (row.id !== this.expandRow[0]) {
                this.expandRow = [row.id]
                refundAdviseList({ refundId: row.id }).then((res) => {
                    this.tableData.forEach((item) => {
                        if (item.id === row.id) {
                            item.adviseList = res.data
                        }
                    })
                })
            } else {
                this.expandRow = []
            }
        },
        getRefundReasonApi() {
            getRefundReason().then((res) => {
                this.refundReasonList = res.data
                const reasonMap = {}
                res.data.forEach((item) => {
                    reasonMap[item.id] = item.name
                    reasonMap[item.type] = item.name
                })
                this.refundReasonMap = reasonMap
            })
        },
    },
}
</script>
<style lang="less" scoped>
.el-table {
    margin-top: 12px;
}
.opts {
    margin: 12px 0;
    .el-button {
        margin-right: 30px;
    }
}

.activity_gift {
    padding: 10px 20px 16px 50px;
    display: flex;
    border-bottom: 1px solid #efefef;
    .label {
        display: inline-block;
        width: 60px;
        text-align: right;
        color: #999;
        font-size: 14px;
        line-height: 2em;
        margin-right: 12px;
    }
    .gift_list {
        flex-grow: 1;
        .item {
            display: flex;
            align-items: center;
            height: 36px;
            font-size: 16px;
            color: #000;
        }
        .price {
            margin-left: 12px;
            color: #f00;
        }
    }
}
.refund_details {
    padding: 16px 20px 16px 50px;
    border-bottom: 1px solid #efefef;
    .label {
        display: inline-block;
        width: 60px;
        text-align: right;
        color: #999;
        font-size: 14px;
        line-height: 1.4em;
        margin-right: 12px;
    }
    .refund_list {
        vertical-align: top;
        display: inline-block;
        width: calc(100% - 90px);
        > div {
            margin-bottom: 18px;
        }
        .time {
            font-size: 14px;
            color: #999;
            span {
                margin-right: 20px;
            }
        }
        pre {
            width: 100%;
            font-size: 16px;
            line-height: 1.4em;
            margin-top: 12px;
            color: #000;
        }
    }
    > pre {
        vertical-align: top;
        color: #000;
        display: inline-block;
        font-size: 15px;
        line-height: 1.4em;
        margin: 0;
        max-width: calc(100% - 90px);
    }
}
.opts {
    padding: 0 0 0 50px;
}
</style>
