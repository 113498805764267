<template>
    <div class="cascader">
        <el-cascader
            ref="cascader"
            :size="size"
            v-if="options.length !== 0"
            v-model="value"
            :clearable="clearable"
            :props="props"
            :options="options"
            :show-all-levels="false"
            :collapse-tags="true"
            @change="onChange"
        />
    </div>
</template>
<script>
import { getStaffGroupList, getStaffList } from '@/js/api/staffApi.js'
import { mapGetters } from 'vuex'

export default {
    computed: { ...mapGetters(['userInfo']) },
    props: ['multiple', 'clearable', 'allGroupList', 'manageGroupList', 'size'],

    data() {
        return {
            props: {
                expandTrigger: 'hover',
                multiple: false,
                lazy: true,
                lazyLoad: this.lazyLoadCascader,
            },
            options: [],
            value: [],
        }
    },
    watch: {
        multiple: function () {
            this.props.multiple = this.multiple
        },
        manageGroupList: function () {
            this.initOptions()
        },
    },
    created() {
        this.props.multiple = this.multiple
        if (this.allGroupList) {
            this.initGroup()
        } else {
            this.initOptions()
        }
    },
    methods: {
        initGroup() {
            getStaffGroupList({}).then((res) => {
                this.groupList = res.data
                this.initOptions()
            })
        },
        async initOptions() {
            const _options = []
            let _groupList = this.manageGroupList || []
            if (_groupList.length === 0) _groupList = this.groupList
            _groupList.forEach((i) => {
                _options.push({ label: i.name, value: i.id })
            })
            if (!this.userInfo.isAdmin && this.userInfo.group) {
                const res = await getStaffList({ groupIds: [this.userInfo.group.id] }, false)
                const _list = res.data.map((i) => {
                    return { value: i.id, label: i.name, leaf: true }
                })

                _options.forEach((g) => {
                    if (g.value === this.userInfo.group.id) {
                        g.children = _list
                    }
                })

                const _val = [this.userInfo.group.id, this.userInfo.id]
                this.value = this.multiple ? [_val] : _val
            }
            this.options = _options
        },
        lazyLoadCascader(node, resolve) {
            if (node.children.length === 0 && node.level === 1) {
                getStaffList({ groupIds: [node.value] }, false).then((res) => {
                    const _list = res.data.map((i) => {
                        return { value: i.id, label: i.name, leaf: true }
                    })
                    resolve(_list)
                })
            } else {
                resolve([])
            }
        },
        onChange() {
            let _val = []
            if (this.value && this.value.length !== 0) {
                if (this.multiple) {
                    this.value.map((i) => {
                        if (i && i[1]) {
                            _val.push(i[1])
                        }
                    })
                } else {
                    _val = this.value.length == 2 ? [this.value[1]] : []
                }
            }
            console.log(_val)
            this.$emit('change', _val)
        },
        onClear() {
            this.value = []
            this.onChange()
        },
    },
}
</script>
<style lang="less" scoped>
.cascader {
    .el-cascader {
        width: 100%;
    }
}
</style>
