<template>
    <el-drawer
        class="userInfo_drawer"
        :title="title"
        :visible.sync="isShow"
        size="90vw"
        :before-close="onClose"
        @opened="onOpened"
    >
        <div v-if="activeItem" class="detail">
            <!-- <UserInfo :editState="editState" @onClose="onClose" :activeItem="activeItem" /> -->
        </div>

        <el-tabs v-model="activeTab">
            <el-tab-pane v-for="tab in tabList" :key="tab.val" :label="tab.name" :name="tab.val" />
        </el-tabs>
        <UserOrder :activeItem="activeItem" v-if="activeTab === 'order'" />
        <UserRefund
            :editState="editState"
            :refundState="refundState"
            :activeItem="activeItem"
            v-if="activeTab === 'refund'"
        />
        <!-- <UserOrder :activeItem="activeItem" v-if="activeTab === 'order'" />
        <UserRefund
            :editState="editState"
            :refundState="refundState"
            :activeItem="activeItem"
            v-if="activeTab === 'refund'"
        /> -->
    </el-drawer>
</template>
<script>
import UserInfo from './UserInfo.vue'
import UserOrder from './UserOrder.vue'
import UserRefund from './UserRefund.vue'
import { mapGetters } from 'vuex'

export default {
    props: ['isShow', 'activeItem'],
    components: { UserInfo, UserOrder, UserRefund },
    computed: {
        ...mapGetters(['userInfo']),
        editState: function () {
            return this.activeItem && this.userInfo.id === this.activeItem.staffId
        },
    },
    data() {
        return {
            title: '',
            tabList: [
                { name: '退款记录', val: 'refund' },
                { name: '历史交易', val: 'order' },
            ],
            activeTab: 'order',
        }
    },
    methods: {
        onOpened() {
            console.log(this.activeItem)
            this.activeTab = 'order'
            this.title = '用户详情'
            if (this.activeItem.userInfo) {
                const { nickname, name } = this.activeItem.userInfo
                this.title = `${name || nickname} - 用户详情`
            }
        },
        onClose() {
            this.$emit('onClose')
        },
    },
}
</script>
<style lang="less" scoped>
/deep/.userInfo_drawer {
    font-size: 12px;
    .el-drawer__header {
        height: 0;
        margin-bottom: 12px;
    }
    .detail {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
}

/deep/ .title {
    font-size: 14px;
    font-weight: 700;
    line-height: 2em;
    height: 2em;
}
</style>
