<template>
    <div class="userInfo">
        <div class="title">
            <span>基本信息</span>
            <el-popconfirm v-if="editState" class="edit_btn" title="移除后，将回到待领取列表" @confirm="onDelPaidUser">
                <el-link slot="reference" type="warning">不是我的学员</el-link>
            </el-popconfirm>
        </div>
        <div class="user_item">
            <div>
                <div class="label">学员ID:</div>
                <el-input readonly size="mini" class="content" :value="userInfo.id" />
            </div>
            <div>
                <div class="label">姓名:</div>
                <el-input readonly size="mini" class="content" :value="userInfo.name" />
            </div>
            <div>
                <div class="label">手机号:</div>
                <el-input readonly size="mini" class="content" :value="userInfo.telNo" />
            </div>
        </div>
        <div class="user_item">
            <div>
                <div class="label">报名时间:</div>
                <el-input readonly size="mini" class="content" :value="orderInfo.paidTime" />
            </div>
            <div>
                <div class="label">报名课程:</div>
                <el-input readonly size="mini" class="content" :value="orderInfo.packageName" />
            </div>
            <div>
                <div class="label">缴费金额:</div>
                <el-input readonly size="mini" class="content" :value="`¥${orderInfo.paidFee / 100}`" />
            </div>
        </div>
        <div class="user_item">
            <div>
                <div class="label">班级群:</div>
                <el-input readonly size="mini" class="content" :value="teacherInfo.cpGroupName" />
            </div>
            <div>
                <div class="label">班主任微信:</div>
                <el-input readonly size="mini" class="content" :value="teacherInfo.staffCid" />
            </div>
            <div>
                <div class="label">成单运营:</div>
                <el-input readonly size="mini" class="content" :value="orderStaffName" />
            </div>
        </div>

        <div class="user_item">
            <div>
                <div class="label">订单状态:</div>
                <el-tag v-if="refundState === 'PAID'">{{ paidOrderStatusMap['PAID'] }}</el-tag>
                <el-tag type="warning" v-else-if="refundState === 'DETAINING'">
                    {{ paidOrderStatusMap['DETAINING'] }}
                </el-tag>
                <el-tag v-else type="info">{{ paidOrderStatusMap[refundState] || '未知' }}</el-tag>
            </div>
            <div>
                <div class="label">备注:</div>
                <el-input readonly size="mini" class="content" :value="remark" />
            </div>
        </div>
    </div>
</template>
<script>
import { clazzTeacherStudentDel } from '@/js/api/userApi.js'
import { getOppoUserTradeList } from '@/js/api/userApi.js'
import { paidOrderStatusMap } from '@/js/config/order.js'
import { getStaffList } from '@/js/api/staffApi'

export default {
    props: ['activeItem', 'refundState', 'editState'],
    data() {
        return {
            paidOrderStatusMap,
            userInfo: null,
            orderInfo: null,
            teacherInfo: null,

            orderStaffName: '',
            remark: '',
        }
    },
    created() {
        const { userInfo, userId, packageInfo, paidTime, paidFee, teacherStudent } = this.activeItem
        this.userInfo = { ...userInfo, id: userId }
        this.orderInfo = { paidTime, paidFee, packageName: `[${packageInfo.id}]${packageInfo.title}` }

        if (teacherStudent) {
            this.teacherInfo = { cpGroupName: teacherStudent.cpGroupName, staffCid: teacherStudent.staffCid }
            this.remark = teacherStudent.remark
        }
        this.initData()
    },
    methods: {
        onDelPaidUser() {
            clazzTeacherStudentDel({
                id: this.activeItem.teacherStudent.id,
            }).then(() => {
                this.$message.success('移除成功')
                this.$emit('onClose')
            })
        },
        async initData() {
            const res1 = await getOppoUserTradeList({ orderIds: [this.activeItem.orderId] })
            if (res1.data[0] && res1.data[0].staffId) {
                const res2 = await getStaffList({ staffIds: [res1.data[0].staffId] })
                this.orderStaffName = res2.data[0]['name']
            }
        },
    },
}
</script>
<style lang="less" scoped>
.userInfo {
    width: 50%;
    margin-bottom: 20px;
    .edit_btn {
        font-size: 13px;
        margin-left: 20px;
    }
}
.user_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    > div {
        flex-basis: 33%;
        display: flex;
        align-items: center;
        height: 28px;
    }
    .label {
        width: 72px;
        flex-shrink: 0;
        text-align: right;
        margin-right: 8px;
        font-size: 13px;
    }
    .content {
        flex-grow: 1;
        line-height: 28px;
        font-size: 13px;
        font-weight: 700;
    }
    /deep/ input {
        flex-grow: 1;
        padding: 0 12px;
        background: #efefef;
    }
}
</style>
