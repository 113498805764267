<template>
    <el-dialog
        title="确认信息"
        :append-to-body="true"
        :visible.sync="isShow"
        :before-close="onClose"
        width="420px"
        @opened="onOpened"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
    >
        <el-form ref="formData" :model="formData" :rules="rules" label-width="80px">
            <el-form-item label="退款类型" prop="refundType">
                <el-radio-group v-model="formData.refundType" style="width: 100%">
                    <template v-for="item in refundTypeList">
                        <el-radio-button :key="item.val" :label="item.val">
                            {{ item.name }}
                        </el-radio-button>
                    </template>
                </el-radio-group>
                <template v-for="item in refundTypeList">
                    <div :key="item.val" v-if="formData.refundType === item.val" class="refundType_desc">
                        {{ item.desc }}
                    </div>
                </template>
            </el-form-item>
            <el-form-item label="退款分类" prop="refundReasonType">
                <el-select v-model="formData.refundReasonType" filterable style="width: 100%">
                    <el-option
                        v-for="item in refundReasonList"
                        :label="item.name"
                        :value="item.type"
                        :key="item.type"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="退款原因" prop="refundReason">
                <el-input type="textarea" :maxlength="128" show-word-limit v-model="formData.refundReason" :rows="3" />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { refundTypeList } from '@/js/config/order.js'
import { tradePayOrderRefundMod, getRefundReason } from '@/js/api/userApi.js'
import { mapGetters } from 'vuex'

export default {
    props: ['isShow', 'refundInfo'],
    computed: { ...mapGetters(['userInfo']) },
    data() {
        return {
            refundTypeList,
            formData: {
                refundType: '',
                refundReasonType: '',
                refundReason: '',
            },
            refundReasonList: [],
            rules: {
                refundType: [{ required: true, message: '请选择', trigger: 'blur' }],
                refundReason: [{ required: true, message: '请输入', trigger: 'blur' }],
                refundReasonType: [{ required: true, message: '请选择', trigger: 'blur' }],
            },
        }
    },
    methods: {
        onOpened() {
            this.formData.refundType = this.refundInfo.refundType
            this.formData.refundReasonType = this.refundInfo.refundReasonType
            this.formData.refundReason = this.refundInfo.refundReason
            this.getRefundReasonApi()
        },
        onClose() {
            this.$emit('onClose')
        },
        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    const params = {
                        id: this.refundInfo.id,
                        refundState: 'RETAINED',
                        staffId: this.userInfo.id,
                        refundType: this.formData.refundType,
                        refundReasonType: this.formData.refundReasonType,
                        refundReason: this.formData.refundReason,
                    }
                    tradePayOrderRefundMod(params).then(() => {
                        this.$message.success('取消退款')
                        this.$emit('onClose', true)
                    })
                }
            })
        },
        getRefundReasonApi() {
            getRefundReason().then((res) => {
                this.refundReasonList = res.data
            })
        },
    },
}
</script>
